import * as yup from 'yup';

export const GlobalCostsSchema = yup.object().shape({
	markUp: yup
		.number()
		.typeError('Wartość musi być liczbą.')
		.required('Wartość jest wymagana')
		.min(0, 'Cena musi być liczbą nieujemną'),
	transport: yup
		.number()
		.typeError('Wartość musi być liczbą.')
		.required('Koszt jest wymagany')
		.min(0, 'Koszt musi być liczbą nieujemną'),
});
