import React from 'react';
import styles from './Info.module.scss';
import {InfoProps} from './InfoProps';
import {ReactComponent as InfoSvg} from '@assets/icons/info.svg';
import {Tooltip} from 'react-tooltip';
import ReactDOM from 'react-dom';

const TooltipPortal = ({children}: {children: React.ReactNode}) => {
	return ReactDOM.createPortal(children, document.body);
};

const Info = (props: InfoProps) => {
	const {id, content, icon} = props;

	return (
		<>
			<div
				className={styles.info}
				data-tooltip-id={`my-tooltip-${id}`}
				data-tooltip-content={content}>
				{!!icon ? icon : <InfoSvg />}
			</div>
			<TooltipPortal>
				<Tooltip
					id={`my-tooltip-${id}`}
					className={styles.tooltip}
					style={{zIndex: 99999, fontSize: 14}}
					opacity={1}
				/>
			</TooltipPortal>
		</>
	);
};

export default Info;
