import {requestCreator} from '@helpers/requsetCreator';
import {
	GetAdminCostsResponse,
	SetAdminDataRequest,
} from './models/adminDataModels';
import qs from "qs"

export const getAdminCostsRequest =
	async (): Promise<GetAdminCostsResponse> => {
		const res = await requestCreator<undefined, GetAdminCostsResponse>(
			'GET',
			`/costs/default`
		);

		return res;
	};

export const setAdminCostsRequest = async (
	data: SetAdminDataRequest
): Promise<GetAdminCostsResponse> => {
	const res = await requestCreator<SetAdminDataRequest, GetAdminCostsResponse>(
		'POST',
		`/costs/default?${qs.stringify(data)}`,
		undefined,
    undefined,
    "Zaktualizowano koszta globalne."
	);

	return res;
};
