import * as yup from 'yup';

const VechicleCostVehicleSchema = yup
	.object()
	.shape({
		id: yup.number().required('Vehicle ID is required'),
		lenght: yup
			.number()
			.typeError('Wartość musi być liczbą.')
			.required('Cena jest wymagana')
			.min(0, 'Cena musi być liczbą nieujemną'),
		price: yup
			.number()
			.typeError('Wartość musi być liczbą.')
			.required('Cena jest wymagana')
			.min(0, 'Cena musi być liczbą nieujemną'),
		cost: yup
			.number()
			.typeError('Wartość musi być liczbą.')
			.required('Koszt jest wymagany')
			.min(0, 'Koszt musi być liczbą nieujemną'),
	})
	.test('cost-price-validation', '', function (values) {
		const {cost, price} = values;

		if (cost < price || (cost === 0 && price === 0)) return true;
		return this.createError({
			path: `${this.path}.price`, // attach error to the price field
			message: 'Cena musi być większa niż koszt, lub oba muszą wynosić 0',
		});
	});

const VechicleCostSchema = yup.object().shape({
	id: yup.number().required('Vehicle ID is required'),
	brand: yup.string().required('Brand is required'),
	model: yup.string().required('Model is required'),
	carBody: yup.string().required('Car body type is required'),
	cabin: yup.string().nullable().default(null),
	chassis: yup.string().required('Chassis is required'),
	dimensions: yup
		.array()
		.of(VechicleCostVehicleSchema)
		.min(0)
		.required('Vehicles are required'),
	isToggled: yup.boolean().required(),
});

const FormSchema = yup.object().shape({
	costs: yup
		.array()
		.of(VechicleCostSchema)
		.min(1, 'At least one property Koszt jest wymagany')
		.required('Costs are required'),
});

export default FormSchema;
