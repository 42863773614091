import AppHeader from '@components/AppHeader/AppHeader';
import SectionContainer from '@components/SectionContainer/SectionContainer';
import React, {useCallback, useState} from 'react';
import ReportForm from './components/ReportForm/ReportForm';
import {toast} from 'react-toastify';
import ReportDownload from './components/ReportDownload/ReportDownload';

const Reports = () => {
	const [reportCsv, setReportCsv] = useState<string | undefined>(undefined);
	const [fileName, setFileName] = useState<string>('');

	const saveCsvData = (data: string) => {
		setReportCsv(data);
    const name = `${new Date().toLocaleString()}.csv`;
		setFileName(name);
	};

	const downloadCsv = useCallback(() => {
		if (reportCsv?.length === 0 || reportCsv === undefined) {
			toast.error('Brak danych do wygenerowania CSV');
			return;
		}

		const blob = new Blob([reportCsv], {type: 'text/csv;charset=utf-8;'});
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');

		link.setAttribute('href', url);
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}, [fileName, reportCsv]);

	return (
		<>
			<AppHeader title={`Raporty`} text='' />
			<SectionContainer title='Wygeneruj raport'>
				<ReportForm saveCsvData={saveCsvData} />
			</SectionContainer>
			{!!reportCsv && (
				<ReportDownload downloadCsv={downloadCsv} fileName={fileName} />
			)}
		</>
	);
};

export default Reports;
