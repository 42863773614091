export const getFieldValueByDynamicKey = (obj: any, key: string): any => {
  var keySplit = key.split('.');
  if (!!!obj) return;
  if (keySplit.length > 1) {
    return getFieldValueByDynamicKey(obj[keySplit[0]], keySplit.slice(1, keySplit.length).join('.'));
  }
  if (keySplit.length === 1) {
    if (!obj) {
      return undefined;
    }
    return obj[key];
  } else {
    return obj;
  }
};
