// Types
import {Store} from '@reducers/rootReducer';

export const getPropertiesCostsPage = (state: Store) => state.propertiesCostsPage;

export const getPropertiesCostsPageProperties = (state: Store) =>
	state.propertiesCostsPage.properties;

export const getPropertiesCostsPageMaxPage = (state: Store) =>
	state.propertiesCostsPage.maxPage;

export const getPropertiesCostsPageCurrentPage = (state: Store) =>
	state.propertiesCostsPage.currentPage;
