import {
	VechiclesCostsPage,
	VechiclesCostsPageActions,
} from '@actions/vechiclesCostsPageActions';
// Types
import { GetVechiclesCostsResponse, VechiclesCostModel } from '@api/models/vechiclesCostsModels';

const initialState: VechiclesCostsPage = {
	vechicles: null,
	currentPage: 1,
	maxPage: 1,
};

export const vechiclesCostsPageReducer = (
	state = initialState,
	action: {
		type: VechiclesCostsPageActions;
		payload:
			| GetVechiclesCostsResponse
			| VechiclesCostModel[]
			| VechiclesCostModel
			| number;
	}
): VechiclesCostsPage => {
	switch (action.type) {
		case VechiclesCostsPageActions.SET_VECHICLES:
			return {
				...state,
				vechicles: (action.payload as GetVechiclesCostsResponse).vechicleList,
				maxPage: (action.payload as GetVechiclesCostsResponse).totalPages,
				currentPage: (action.payload as GetVechiclesCostsResponse).pageIndex,
			};
		case VechiclesCostsPageActions.SET_VECHICLES_LIST:
			return {
				...state,
				vechicles: action.payload as VechiclesCostModel[],
			};
		case VechiclesCostsPageActions.SET_VECHICLES_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case VechiclesCostsPageActions.SET_VECHICLES_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
