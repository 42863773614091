export const safetyAction = (
	submitHandler: () => void,
	beforeSubmit?: () => boolean
): false | void => {
	if (beforeSubmit) {
		const isChanged = !beforeSubmit();
		if (isChanged) {
			if (
				window.confirm(
					'Zmiany dokonane w tabeli nie zostały zapisane. Czy chcesz kontynuować bez zapisu zmian?'
				)
			) {
				submitHandler();
			} else {
				return false;
			}
		} else {
			submitHandler();
		}
	} else {
		submitHandler();
	}
};
