import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components
// import Checkbox from '@components/_FormElements/Checkbox/Checkbox';
// Assets
import {ReactComponent as SortSvg} from '@assets/icons/sort.svg';
// Styles
import styles from './TableHeader.module.scss';

export interface TableHeaderProps {
	headers: {text: string; sortName?: string}[];
}

const TableHeader = (props: TableHeaderProps) => {
	const {headers} = props;
	const [searchParams, setSearchParams] = useSearchParams();
	
	const navigate = useNavigate()

	const sortToggle = (ascending: boolean, sortName?: string) => {
		const updatedSearchParams = new URLSearchParams(searchParams);
		updatedSearchParams.set(`sort`, `${sortName}${ascending ? '&asc' : '&dsc'}`);
		setSearchParams(updatedSearchParams);
		navigate(`?${updatedSearchParams.toString()}`);
	}

	return (
		<thead className={styles.wrapper}>
			<tr>
				{headers.map(({text, sortName}, index) => (
					<th className={styles.item} key={text + index}>
						<span className={styles.itemText}>
							{text}
							{sortName && (
								<span className={styles.sort}>
									<button type='button' data-selected={searchParams.get('sort') === `${sortName}&asc`} className={styles.sortButton} onClick={() => sortToggle(true, sortName)}>
										<SortSvg />
									</button>
									<button type='button' data-selected={searchParams.get('sort') === `${sortName}&dsc`} className={styles.sortButton} onClick={() => sortToggle(false, sortName)}>
										<SortSvg />
									</button>
								</span>
							)}
						</span>
					</th>
				))}
			</tr>
		</thead>
	);
};

export default TableHeader;
