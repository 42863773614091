// Libraries
import classNames from 'classnames';
import ReactSelect, {
	GroupBase,
	OptionProps,
	ValueContainerProps,
} from 'react-select';
import {Control, Controller} from 'react-hook-form';
import styles from './FormSelect.module.scss';
import {getFieldValueByDynamicKey} from '@helpers/objectHelper';

interface FormSelectProps {
	name: string;
	options:
		| {label: string; value: any}[]
		| {label: string; value: any}[];
	label?: string;
	classes?: string;
	control: Control<any>;
	isMulti: boolean;
	menuPortal: boolean;
	selectClasses?: string;
	closeMenuOnSelect: boolean;
	values?: {label: string; value: string}[] | {label: string; value: string};
	disabledOptions?: string[];
	onBlur?: () => void;
}

const ValueComponent = (
	props: ValueContainerProps<any, boolean, GroupBase<any>>
) => {
	if (Array.isArray((props.children as any)[0])) {
		if (!!props.selectProps.menuPortalTarget) {
			return (
				<div className={styles.containerWrapper}>
					{`(${(props.children as any)[0].length})`}
					{props.children}
				</div>
			);
		} else {
			const value = Array.isArray(props.selectProps.value)
				? (props.selectProps.value as any).map((x: any) => x.label).join(', ')
				: (props.selectProps.value as any).label;

			return <div className={styles.containerWrapper}>{value}</div>;
		}
	}
	return <div className={styles.containerWrapper}>{props.children}</div>;
};

const OptionComponent = (props: OptionProps<any, boolean, GroupBase<any>>) => {
	return (
		<div
			data-current={props.isSelected}
			className={classNames({
				[styles.option]: !props.isMulti,
				[styles.multiOption]: !!props.isMulti,
				[styles.disabledOption]: props.isDisabled,
			})}
			key={`${props.data.label}`}
			onChange={() => null}
			onClick={() => !props.isDisabled && props.selectOption(props.data)}>
			<span>{props.data.label}</span>
		</div>
	);
};

const FormSelect = (props: FormSelectProps) => {
	const {
		options,
		classes,
		label,
		control,
		closeMenuOnSelect,
		name,
		values,
		isMulti,
		menuPortal,
		selectClasses,
		disabledOptions,
		onBlur,
	} = props;

	return (
		<div className={classNames('input select', classes)}>
			<label className={classNames(styles.label, 'input__label')}>
				{label && <span className='input__label-text'>{label}</span>}
				<Controller
					control={control}
					name={name}
					render={(e: any) => {
						const errors = e.formState.errors;
						let error = {message: null};
						if (Object.keys(errors).length > 0) {
							error = getFieldValueByDynamicKey(errors, name);
						}
						return (
							<ReactSelect
								isSearchable={false}
								isMulti={isMulti}
								ref={e.field.ref}
								options={options}
								defaultValue={values}
								openMenuOnClick={true}
								hideSelectedOptions={false}
								isClearable={false}
								isOptionDisabled={(option) =>
									typeof option.value !== 'boolean'
										? disabledOptions?.includes(option.value) ?? false
										: false
								}
								menuPortalTarget={menuPortal ? document.body : undefined}
								menuPosition={menuPortal ? 'fixed' : 'absolute'}
								closeMenuOnSelect={closeMenuOnSelect}
								className={classNames('select', selectClasses, {
									[styles.error]: !!error?.message,
								})}
								styles={{
									menuPortal: (base) => ({...base, zIndex: 9999999, boxShadow: 'none'}),
								}}
								value={
									Array.isArray(e.field.value)
										? options.filter((x) => e.field.value.includes(x.value))
										: options.find((x) => x.value === e.field.value)
								}
								getOptionLabel={(option: any) => option?.label}
								onChange={(new_value: any) => {
									if (Array.isArray(new_value)) {
										e.field.onChange(new_value.map((x) => x.value));
										return;
									}
									e.field.onChange(new_value.value);
								}}
								onMenuClose={onBlur}
								components={{
									ValueContainer: ValueComponent,
									Input: () => null,
									Option: OptionComponent,
								}}
							/>
						);
					}}
				/>
			</label>
		</div>
	);
};

FormSelect.defaultProps = {
	isMulti: false,
	menuPortal: true,
	closeMenuOnSelect: false,
};

export default FormSelect;
