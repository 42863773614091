import {AdminFullOfferModel} from '@api/models/offer';

export interface PreviewOfferPage {
	offer: AdminFullOfferModel | null;
}

export enum PreviewOfferPageActions {
	SET_PREVIEW_OFFER = 'SET_PREVIEW_OFFER',
	CLEAR_PREVIEW_OFFER = 'CLEAR_PREVIEW_OFFER',
}

const setPreviewOffer = (offer: AdminFullOfferModel) => ({
	type: PreviewOfferPageActions.SET_PREVIEW_OFFER,
	payload: offer,
});

const clearPreviewOffer = () => ({
	type: PreviewOfferPageActions.CLEAR_PREVIEW_OFFER,
});

export {setPreviewOffer, clearPreviewOffer};
