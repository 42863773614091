import {requestCreator} from '@helpers/requsetCreator';
import {
	AdminFullOfferModel,
	FullOfferModel,
	GetOfferResponse,
	UpdateDetailCostsRequest,
} from './models/offer';
import {setOrderedConfigs} from '@actions/orderedConfigsPageActions';
import {Dispatch} from 'react';
import {UnknownAction} from '@reduxjs/toolkit';
import {getFilterParams} from '@helpers/getFilterParams';
import qs from 'qs';

export const getOffersRequest = async (
	dispatch: Dispatch<UnknownAction>,
	pageIndex: number,
	pageSize: number,
	filters?: {
		brandIds?: number;
		bodyTypeIds?: number;
		searchValue?: string;
		startDate?: string;
		endDate?: string;
	}
): Promise<GetOfferResponse> => {
	let url = `/offer?pageIndex=${pageIndex}&pageSize=${pageSize}`;

	const res = await requestCreator<undefined, GetOfferResponse>(
		'GET',
		getFilterParams(
			{
				...filters,
				startDate: filters?.startDate ? new Date(filters?.startDate).toJSON() : '',
				endDate: filters?.endDate ? new Date(filters?.endDate).toJSON() : '',
			},
			url
		)
	);

	dispatch(setOrderedConfigs(res));

	return res;
};

export const getOfferDetailsRequest = async (
	id: string
): Promise<FullOfferModel> =>
	await requestCreator<undefined, FullOfferModel>('GET', `/offer/details/${id}`);

export const getAdminOfferDetailsRequest = async (
	id: string
): Promise<AdminFullOfferModel> =>
	await requestCreator<undefined, AdminFullOfferModel>(
		'GET',
		`/offer/admin/details/${id}`
	);

export const setAdminOfferCostsRequest = async (
	data: UpdateDetailCostsRequest
): Promise<AdminFullOfferModel> => {
	const res = await requestCreator<UpdateDetailCostsRequest, AdminFullOfferModel>(
		'POST',
		`/offer/admin/details?${qs.stringify(data)}`,
		undefined,
		undefined,
		'Zaktualizowano koszta.'
	);

	return res;
};
