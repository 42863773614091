import {UpdateCostElementModel} from '@api/models/propertiesModels';
import {UseFormGetValues} from 'react-hook-form';

export const getConstItems = (
	attribute: string,
	getValues: UseFormGetValues<any>
): UpdateCostElementModel => {
	const attributeFormValue = getValues(attribute);
	const attributeValue = {
		id: attributeFormValue.id,
		cost: +attributeFormValue.cost,
		price: +attributeFormValue.price,
	};

	return attributeValue;
};
