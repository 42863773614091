// Components
import AppHeader from '@components/AppHeader/AppHeader';
import PropertiesList from './components/PropertiesList/PropertiesList';
// Styles
import styles from './PropertiesCost.module.scss';

const PropertiesCost = () => {
	return (
		<>
			<AppHeader
				title='Zarządzanie kosztami cech'
				text=''
				classes={styles.header}>
			</AppHeader>
			<PropertiesList />
		</>
	);
};

export default PropertiesCost;
