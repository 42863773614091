export enum RouterPaths {
	Login = '/login',
	Brands = '/',
	AdminData = '/admin-data',
	Categories = '/categories',
	Models = '/models',
	Bodies = '/bodies',
	Vehicles = '/vehicles',
	VehiclesAdd = '/vehicles/add',
	VehiclesCost = '/cost-vehicles',
	Properties = '/properties',
	PropertiesVehicles = '/properties/vehicles-manage/:id',
	PropertiesDependencies = '/properties/dependencies/:id',
	PropertiesCost = '/cost-properties',
	Help = '/help',
	Tabs = '/tabs',
	TabsConfig = '/tabs/configure/:id',
	Users = '/users',
	Configurations = '/configurations',
	ConfigurationsDetails = '/configurations/:id',
	Reports = '/reports',
	Error = '/*',
}
