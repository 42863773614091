import {UnknownAction} from '@reduxjs/toolkit';
import {Dispatch} from 'react';
import {
	GetVechiclesCostsResponse,
	UpdateVechiclesCostsData,
} from './models/vechiclesCostsModels';
import {getFilterParams} from '@helpers/getFilterParams';
import {requestCreator} from '@helpers/requsetCreator';
import {setVechiclesCosts} from '@actions/vechiclesCostsPageActions';

export const getVechiclesCostsRequest = async (
	dispatch: Dispatch<UnknownAction> | false,
	pageIndex: number,
	pageSize: number,
	filters?: {vechicleName?: string; brandId?: number; bodyTypeId?: number}
): Promise<GetVechiclesCostsResponse> => {
	let url = `/costs/vechicles?pageIndex=${pageIndex}&pageSize=${pageSize}`;

	const res = await requestCreator<undefined, GetVechiclesCostsResponse>(
		'GET',
		getFilterParams(filters, url)
	);

	if (dispatch) {
		dispatch(setVechiclesCosts(res));
	}

	return res;
};

export const updateVechiclesCostsRequest = async (
	data: UpdateVechiclesCostsData
): Promise<any> => {
	await requestCreator<UpdateVechiclesCostsData, any>(
		'POST',
		`/costs/edit-vechicles`,
		data,
		undefined,
		'Dane zaktualizowane poprawnie'
	);
};
