import { GetVechiclesCostsResponse, VechiclesCostModel } from '@api/models/vechiclesCostsModels';

export interface VechiclesCostsPage {
	vechicles: VechiclesCostModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum VechiclesCostsPageActions {
	SET_VECHICLES = 'SET_VECHICLES',
	SET_VECHICLES_LIST = 'SET_VECHICLES_LIST',
	SET_VECHICLES_CURRENT_PAGE = 'SET_VECHICLES_CURRENT_PAGE',
	SET_VECHICLES_MAX_PAGE = 'SET_VECHICLES_MAX_PAGE',
}

const setVechiclesCosts = (vechicles: GetVechiclesCostsResponse) => ({
	type: VechiclesCostsPageActions.SET_VECHICLES,
	payload: vechicles,
});

const setVechiclesCostsList = (vechicles: VechiclesCostModel[]) => ({
	type: VechiclesCostsPageActions.SET_VECHICLES_LIST,
	payload: vechicles,
});

const setVechiclesCostsCurrentPage = (currentPage: number) => ({
	type: VechiclesCostsPageActions.SET_VECHICLES_CURRENT_PAGE,
	payload: currentPage,
});

const setVechiclesCostsMaxPage = (maxPage: number) => ({
	type: VechiclesCostsPageActions.SET_VECHICLES_MAX_PAGE,
	payload: maxPage,
});

export {
	setVechiclesCosts,
	setVechiclesCostsCurrentPage,
	setVechiclesCostsMaxPage,
	setVechiclesCostsList,
};
