export enum ReportTypeEnum {
	Client = 1,
	Brand = 2,
	BodyType = 3,
	Vechicle = 4,
	VechicleList = 5,
}

export interface GetReportRequestModel {
	reportType: ReportTypeEnum;
	start: string;
	end: string;
}
