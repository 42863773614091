import {VehicleDetailsModel} from '@api/models/vehiclesModels';
import Button, {ButtonVariant} from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import {useEffect, useMemo, useState} from 'react';
import styles from './SettingsModal.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {loadingAction} from '@actions/appActions';
import {editVehicleDataRequest} from '@api/vehicles';
import {toast} from 'react-toastify';
import {BodyModel} from '@api/models/bodyModels';
import {useForm} from 'react-hook-form';
import FormSelect from '@components/_useFormElements/FormSelect/FormSelect';
import {getModelsPageModels} from '@selectors/modelsPageSelectors';
import {yupResolver} from '@hookform/resolvers/yup';
import {VehicleSchema} from './SettingsModalSchema';
import {getModelsRequest} from '@api/models';
import {editVechicleDataState} from '@actions/vehiclesPageActions';
import {Text} from '@components/_Typography';

interface SettingsModalProp {
	vehicle: VehicleDetailsModel | false;
	bodies: BodyModel[];
	closeHandler: () => void;
}

const SettingsModal = (props: SettingsModalProp) => {
	const {vehicle, closeHandler, bodies} = props;

	const [caution, setCaution] = useState(false);

	const models = useSelector(getModelsPageModels);
	const dispatch = useDispatch();

	const {control, handleSubmit, reset} = useForm({
		defaultValues: {
			body: `${vehicle ? vehicle.bodyTypeId : 0}`,
			model: `${vehicle ? vehicle.modelId : 0}`,
		},
		resolver: yupResolver(VehicleSchema),
	});

	useEffect(() => {
		(async () => {
			if (!vehicle) return;
			setCaution(false);
			dispatch(loadingAction(true));
			await getModelsRequest(dispatch, 1, 99, {
				brandId: vehicle.brandId ? `${vehicle.brandId}` : undefined,
			});
			dispatch(loadingAction(false));
			reset({
				body: `${vehicle ? vehicle.bodyTypeId : 0}`,
				model: `${vehicle ? vehicle.modelId : 0}`,
			});
		})();
	}, [dispatch, reset, vehicle]);

	const preSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		setCaution(true);
	};

	const onSubmit = handleSubmit(async (data) => {
		if (!vehicle) return toast.error('Id pojazdu nierozpoznane!');
		dispatch(loadingAction(true));
		await editVehicleDataRequest({
			vechcileId: vehicle.id,
			modelId: +data.model,
			bodyTypeId: +data.body,
		}).then(() => {
			dispatch(
				editVechicleDataState({
					id: vehicle.id,
					modelId: +data.model,
					model: models?.find((item) => item.id === +data.model)?.name || '',
					bodyTypeId: +data.body,
					bodyType: bodies?.find((item) => item.id === +data.body)?.name || '',
				})
			);
		});
		closeHandler();
		dispatch(loadingAction(false));
	});

	const modelOptions = useMemo(
		() =>
			models
				? models.map((item) => ({label: item.name, value: `${item.id}`}))
				: [],
		[models]
	);
	const bodyOptions = useMemo(
		() =>
			bodies
				? bodies.map((item) => ({label: item.name, value: `${item.id}`}))
				: [],
		[bodies]
	);

	if (vehicle === false) return <></>;

	return (
		<Modal
			title={
				caution
					? ''
					: `Modyfikujesz dane podstawowe pojazdu ${vehicle.brand} ${
							vehicle.model
					  } ${vehicle.bodyType} ${vehicle.cabinType ?? ''} ${vehicle.chassis}`
			}
			closeHandler={closeHandler}>
			<form onSubmit={caution === false ? preSubmit : onSubmit}>
				{caution ? (
					<Text>
						<strong>Czy na pewno chcesz wprowadzić zmiany w pojeździe?</strong>
					</Text>
				) : (
					<>
						<FormSelect
							control={control}
							name={'body'}
							label='Rodzaj zabudowy'
							options={bodyOptions}
						/>
						<FormSelect
							control={control}
							name={'model'}
							label='Model'
							options={modelOptions}
						/>
					</>
				)}
				<div className={styles.buttons}>
					<Button
						type='button'
						onClick={closeHandler}
						variant={ButtonVariant.OutlineWithBorder}>
						Anuluj
					</Button>
					<Button type='submit'>Zatwierdź</Button>
				</div>
			</form>
		</Modal>
	);
};

export default SettingsModal;
