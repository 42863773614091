import * as yup from 'yup';

const PropertyCostVehicleSchema = yup.object().shape({
	id: yup.number().required('Vehicle ID is required'),
	attributeId: yup.number().required('Attribute ID is required'),
	brand: yup.string().required('Brand is required'),
	model: yup.string().required('Model is required'),
	carBody: yup.string().required('Car body type is required'),
	cabin: yup.string().nullable().default(null),
	chassis: yup.string().required('Chassis is required'),
	price: yup
		.number()
		.typeError('Wartość musi być liczbą.')
		.required('Cena jest wymagana'),
	cost: yup
		.number()
		.typeError('Wartość musi być liczbą.')
		.required('Koszt jest wymagany'),
});

const PropertyCostSchema = yup.object().shape({
	category: yup.string().required('Category is required'),
	imageUrl: yup.string(),
	name: yup.string().required('Property name is required'),
	id: yup.number().required('Property ID is required'),
	cost: yup
		.number()
		.typeError('Wartość musi być liczbą.')
		.required('Koszt jest wymagany'),
	price: yup
		.number()
		.typeError('Wartość musi być liczbą.')
		.required('Cena jest wymagana'),
	vechicles: yup
		.array()
		.of(PropertyCostVehicleSchema)
		.min(0)
		.required('Vehicles are required'),
	isEuroPrice: yup.boolean().required(),
	isToggled: yup.boolean().required(),
});

const FormSchema = yup.object().shape({
	costs: yup
		.array()
		.of(PropertyCostSchema)
		.min(1, 'At least one property Koszt jest wymagany')
		.required('Costs are required'),
});

export default FormSchema;
