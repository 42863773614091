// Libraries
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import deepEqual from 'fast-deep-equal';
import {diff} from 'deep-diff';
// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
// API
import {
	getPropertiesCostsRequest,
	updatePropertiesCostsRequest,
} from '@api/propertiesCosts';
// Assets
import emptyImage from '@assets/images/empty-image.png';
import {ReactComponent as Arrow} from '@assets/icons/arrow.svg';
// Types
import {Tag} from '@commonTypes/tags';
import Pagination from '@components/Pagination/Pagination';
import Filters from '@components/Filters/Filters';
import {getCategoriesRequest} from '@api/categories';
import {CategoryModel} from '@api/models/categoryModels';
import {getVehiclesRequest} from '@api/vehicles';
import {VehicleDetailsModel} from '@api/models/vehiclesModels';
import {
	getPropertiesCostsPageMaxPage,
	getPropertiesCostsPageProperties,
} from '@selectors/propertiesCostsPageSelectors';
import {useFieldArray, useForm, useWatch} from 'react-hook-form';
import FormInput from '@components/_useFormElements/FormInput/FormInput';
import classNames from 'classnames';
import Button from '@components/Button/Button';
import {toast} from 'react-toastify';
import {getConstItems} from '@helpers/getCostsItems';
import {setPropertiesCostsList} from '@actions/propertiesCostsPageActions';
import {PropertyCostFormModel} from './PropertiesList.type';
import styles from './PropertiesList.module.scss';
import {yupResolver} from '@hookform/resolvers/yup';
import FormSchema from './PropertiesListSchema';
import {loadingAction} from '@actions/appActions';
import {omitProps} from '@helpers/omitProps';

const PropertiesList = () => {
	const [allCategories, setAllCategories] = useState<CategoryModel[]>([]);
	const [allVehicles, setAllVehicles] = useState<VehicleDetailsModel[]>([]);
	const storeProperties = useSelector(getPropertiesCostsPageProperties);
	const properties = useMemo(
		() => storeProperties?.map((property) => ({...property, isToggled: false})),
		[storeProperties]
	);
	const dispatch = useDispatch();

	const [defaultState, setDefaultState] = useState<{
		costs: PropertyCostFormModel[];
	}>({costs: []});
	const {control, getValues, reset, setValue, watch, handleSubmit} = useForm<{
		costs: PropertyCostFormModel[];
	}>({
		defaultValues: {costs: properties || []},
		resolver: yupResolver(FormSchema),
	});
	const {fields} = useFieldArray({
		name: 'costs',
		control,
	});

	useEffect(() => {
		if (properties) {
			reset({costs: properties});
			setDefaultState({costs: properties});
		}
	}, [properties, reset]);

	useEffect(() => {
		(async () => {
			await getCategoriesRequest(undefined, 1, 999999999).then((res) =>
				setAllCategories(res.list)
			);
			await getVehiclesRequest(undefined, 1, 999999999).then((res) =>
				setAllVehicles(res.vechicles)
			);
		})();
	}, []);

	const toggledValues = useWatch({
		control,
		name: 'costs',
	})?.map((item) => item?.isToggled);

	const tableElements = useMemo(
		() =>
			fields
				?.map((item, index) => {
					if (!!item) {
						const mainElement = {
							toggler: (
								<button
									type='button'
									onClick={() => {
										setValue(
											`costs.${index}.isToggled`,
											!getValues(`costs.${index}.isToggled`)
										);
									}}
									className={styles.toggle}
									data-toggled={!watch(`costs.${index}.isToggled`)}>
									<Arrow />
								</button>
							),
							name: getValues(`costs.${index}.name`),
							pricture: (
								<img
									className={classNames({'empty-image': !!!item.imageUrl})}
									src={!!item.imageUrl ? item.imageUrl : emptyImage}
									alt={item.name}
								/>
							),
							price: (
								<FormInput
									classes={styles.input}
									control={control}
									id={`costs.${index}.price`}
									name={`costs.${index}.price`}
									label=''
									suffix={item.isEuroPrice ? '€' : 'zł'}
								/>
							),
							cost: (
								<FormInput
									classes={styles.input}
									control={control}
									id={`costs.${index}.cost`}
									name={`costs.${index}.cost`}
									label=''
									suffix={item.isEuroPrice ? '€' : 'zł'}
								/>
							),
						};

						const vechicleElements = item.vechicles.map(
							(vechicle, vechicleIndex) => ({
								isHidden: !watch(`costs.${index}.isToggled`),
								toggler: <></>,
								name: (
									<span style={{display: 'block', paddingLeft: 40}}>{`${
										vechicle.brand
									} ${vechicle.model}${!!vechicle.cabin ? ' ' + vechicle.cabin : ''} ${
										vechicle.chassis
									}`}</span>
								),
								pricture: (
									<img
										className={classNames({'empty-image': !!!item.imageUrl})}
										src={!!item.imageUrl ? item.imageUrl : emptyImage}
										alt={item.name}
									/>
								),
								price: (
									<FormInput
										classes={styles.input}
										control={control}
										id={`costs.${index}.vechicles.${vechicleIndex}.price`}
										name={`costs.${index}.vechicles.${vechicleIndex}.price`}
										label=''
										info={
											'Dla wartości 0 element przyjmuje wartość elementu nadrzędnego.'
										}
										suffix={item.isEuroPrice ? '€' : 'zł'}
									/>
								),
								cost: (
									<FormInput
										classes={styles.input}
										control={control}
										id={`costs.${index}.vechicles.${vechicleIndex}.cost`}
										name={`costs.${index}.vechicles.${vechicleIndex}.cost`}
										label=''
										info={
											'Dla wartości 0 element przyjmuje wartość elementu nadrzędnego.'
										}
										suffix={item.isEuroPrice ? '€' : 'zł'}
									/>
								),
							})
						);

						return item.vechicles?.length
							? [mainElement, ...vechicleElements]
							: mainElement;
					}
					return undefined;
				})
				.flat()
				.filter((item) => !!item),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[control, fields, getValues, setValue, toggledValues, watch]
	);

	const compareChanges = useCallback(() => {
		const ignoreProps = ['isToggled'];
		const defaultForm = omitProps(defaultState, ignoreProps);
		const currentForm = omitProps(getValues(), ignoreProps);
		return deepEqual(defaultForm, currentForm);
	}, [defaultState, getValues]);

	const getChanges = useCallback(() => {
		return diff(defaultState, getValues());
	}, [defaultState, getValues]);

	const submitHandler = useCallback(async () => {
		const changes = getChanges();

		if (!!!changes) return toast.info('Nie wykryto zmian w formularzu.');

		dispatch(loadingAction(true));

		let attributes: any[] = [];
		let vechicleAttributes: any[] = [];

		changes.forEach(({path}) => {
			switch (path?.length) {
				case 3:
					const attribute = path.slice(0, 2).join('.') as `costs.${number}`;

					attributes.push(getConstItems(attribute, getValues));
					break;
				case 5:
					const vechicleAttribute = path
						.slice(0, 4)
						.join('.') as `costs.${number}.vehicles.${number}`;

					vechicleAttributes.push(getConstItems(vechicleAttribute, getValues));
					break;
				default:
					break;
			}
		});

		await updatePropertiesCostsRequest({attributes, vechicleAttributes}).then(
			() => {
				dispatch(setPropertiesCostsList(getValues('costs')));
			}
		);

		dispatch(loadingAction(false));
	}, [dispatch, getChanges, getValues]);

	return (
		<Container as={Tag.Section}>
			<Filters
				compareForm={compareChanges}
				filters={[
					{
						name: 'attributeName',
						label: 'Wyszukiwarka',
						as: 'input',
					},
					{
						name: 'categoriesIds',
						label: 'Kategorie',
						options: allCategories.map((cat) => ({
							value: `${cat.id}`,
							name: cat.name,
						})),
					},
					{
						name: 'vechicleIds',
						label: 'Pojazd',
						options: allVehicles.map((vehicle) => ({
							value: `${vehicle.id}`,
							name: `${vehicle.brand} ${vehicle.model} ${vehicle.bodyType} ${
								vehicle.cabinType ?? ''
							} ${vehicle.chassis}`,
						})),
					},
				]}
			/>
			<form onSubmit={handleSubmit(submitHandler)}>
				<Table
					headers={[
						{text: ''},
						{text: ''},
						{text: 'Zdjęcie', sortName: 'pictrue'},
						{text: 'Cena', sortName: 'price'},
						{text: 'Koszt', sortName: 'cost'},
					]}
					elements={tableElements ?? []}
					groups={[
						{width: '1%'},
						{width: '48%', minWidth: '200px'},
						{width: '1%', minWidth: '60px'},
						{width: '25%', minWidth: '90px'},
						{width: '25%', minWidth: '90px'},
					]}
				/>
				<Button type='submit' classes={styles.submit}>
					Zapisz zmiany
				</Button>
			</form>
			<Pagination
				beforeNavigate={compareChanges}
				request={getPropertiesCostsRequest}
				getMaxPages={getPropertiesCostsPageMaxPage}
			/>
		</Container>
	);
};

export default PropertiesList;
