// Actions
import {
	PreviewOfferPage,
	PreviewOfferPageActions,
} from '@actions/previewOfferActions';
// Types
import {AdminFullOfferModel} from '@api/models/offer';

const initialState: PreviewOfferPage = {
	offer: null,
};

export const previewOfferPageReducer = (
	state = initialState,
	action: {
		type: PreviewOfferPageActions;
		payload: AdminFullOfferModel;
	}
): PreviewOfferPage => {
	switch (action.type) {
		case PreviewOfferPageActions.SET_PREVIEW_OFFER:
			return {
				...state,
				offer: action.payload as AdminFullOfferModel,
			};
		case PreviewOfferPageActions.CLEAR_PREVIEW_OFFER:
			return {
				...state,
				offer: null,
			};
		default:
			return state;
	}
};
