// Components
import AppHeader from '@components/AppHeader/AppHeader';
import VechiclesCostList from './components/VechiclesCostList/VechiclesCostList'
// Styles
import styles from './VechiclesCost.module.scss';

const VechiclesCost = () => {
	return (
		<>
			<AppHeader
				title='Zarządzanie kosztami pojazdów'
				text=''
				classes={styles.header}>
			</AppHeader>
			<VechiclesCostList />
		</>
	);
};

export default VechiclesCost;
