// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
// Types
import {Tag} from '@commonTypes/tags';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo, useState} from 'react';
import {
	getOrderedConfigs,
	getOrderedConfigsMaxPage,
} from '@selectors/orderedConfigsPageSelectors';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import Pagination from '@components/Pagination/Pagination';
import {getOffersRequest} from '@api/offer';
import {RouterPaths} from '@commonTypes/routerPaths';
import Filters from '@components/Filters/Filters';
import {loadingAction} from '@actions/appActions';
import {getBrandsRequest} from '@api/brands';
import {getBodiesRequset} from '@api/body';
import {BodyModel} from '@api/models/bodyModels';
import {BrandModel} from '@api/models/brandsModels';

const OrderedConfigs = () => {
	const dispatch = useDispatch();

	const configs = useSelector(getOrderedConfigs);
	const [allBrands, setAllBrands] = useState<BrandModel[]>([]);
	const [allBodies, setAllBodies] = useState<BodyModel[]>([]);

	const tableElements = useMemo(
		() =>
			configs?.map((item) => {
				return {
					id: item.id,
					offerName: item.offerName,
					user: item.receiver,
					email: item.email,
					name: item.vechicle,
					date: new Date(item.createDate).toLocaleString(),
					price: `${item.totalPricePln} zł${
						item.totalPriceEuro !== 0 ? ` ${item.totalPriceEuro} €` : ''
					}`,
					cost: `${item.totalCosts} zł${
						item.totalCostsEur !== 0 ? ` ${item.totalCostsEur} €` : ''
					}`,
					profit: `${item.profit} zł${
						item.profitEur !== 0 ? ` ${item.profitEur} €` : ''
					}`,
					edit: (
						<Button
							as={ButtonTag.InternalLink}
							variant={ButtonVariant.Outline}
							href={`${RouterPaths.Configurations}/${item.id}`}>
							Otwórz
						</Button>
					),
				};
			}),
		[configs]
	);

	useEffect(() => {
		(async () => {
			dispatch(loadingAction(true));
			!!!allBrands?.length &&
				(await getBrandsRequest().then((res) => setAllBrands(res)));
			!!!allBodies?.length &&
				(await getBodiesRequset().then((res) => setAllBodies(res)));
			dispatch(loadingAction(false));
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Container as={Tag.Section}>
			<Filters
				filters={[
					{
						name: 'brandIds',
						label: 'Marki',
						options:
							allBrands?.map((brand) => ({
								value: `${brand.id}`,
								name: brand.name,
							})) ?? [],
					},
					{
						name: 'bodyTypeIds',
						label: 'Zabudowy',
						options:
							allBodies?.map((body) => ({
								value: `${body.id}`,
								name: body.name,
							})) ?? [],
					},
					{
						name: 'searchValue',
						label: 'Nazwa',
						as: 'input',
					},
					{
						name: 'startDate',
						label: 'Od dnia',
						as: 'input',
						type: 'date',
					},
					{
						name: 'endDate',
						label: 'Do dnia',
						as: 'input',
						type: 'date',
					},
				]}
			/>
			<Table
				headers={[
					{text: 'ID', sortName: 'id'},
					{text: 'Nazwa', sortName: 'offerName'},
					{text: 'Użytkownik', sortName: 'user'},
					{text: 'E-mail', sortName: 'email'},
					{text: 'Pojazd', sortName: 'name'},
					{text: 'Data', sortName: 'date'},
					{text: 'Cena', sortName: 'price'},
					{text: 'Koszt', sortName: 'cost'},
					{text: 'Zysk', sortName: 'profit'},
					{text: 'Otwórz'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '10%'},
					{width: '9%', minWidth: 125},
					{width: '9%', minWidth: 125},
					{width: '9%', minWidth: 125},
					{width: '9%', minWidth: 225},
					{width: '10%', minWidth: 170},
					{width: '10%', minWidth: 100},
					{width: '10%', minWidth: 100},
					{width: '5%', minWidth: 100},
					{width: '5%'},
				]}
			/>
			<Pagination
				request={getOffersRequest}
				getMaxPages={getOrderedConfigsMaxPage}
			/>
		</Container>
	);
};

export default OrderedConfigs;
