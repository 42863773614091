import React, {useMemo} from 'react';
import SectionContainer from '@components/SectionContainer/SectionContainer';
import {useForm} from 'react-hook-form';
import FormInput from '@components/_useFormElements/FormInput/FormInput';
import Button from '@components/Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {getGlobalCosts} from '@selectors/adminDataSelectors';
import {SetAdminDataRequest} from '@api/models/adminDataModels';
import {yupResolver} from '@hookform/resolvers/yup';
import {GlobalCostsSchema} from './GlobalCostsSchema';
import {setGlobalCostsAction} from '@actions/adminDataActions';
import {setAdminCostsRequest} from '@api/adminData';
import styles from './GlobalCosts.module.scss';

const GlobalCosts: React.FC = () => {
	const stateCosts = useSelector(getGlobalCosts);

	const dispatch = useDispatch();

	const defaultValuse: SetAdminDataRequest = useMemo(
		() => ({
			markUp: stateCosts?.markUp || 0,
			transport: stateCosts?.transport || 0,
		}),
		[stateCosts?.markUp, stateCosts?.transport]
	);
	const {control, handleSubmit} = useForm<SetAdminDataRequest>({
		defaultValues: defaultValuse,
		resolver: yupResolver(GlobalCostsSchema),
	});

	const onSubmit = handleSubmit(async (data) => {
		await setAdminCostsRequest(data).then((res) => {
			dispatch(setGlobalCostsAction(res));
		});
	});

	return (
		<SectionContainer title={'Edycja kosztów globalnych'}>
			<form onSubmit={onSubmit} className={styles.form}>
				<FormInput
					control={control}
					id={`transport`}
					name={`transport`}
					label='Koszt transportu'
					suffix='zł'
				/>
				<FormInput
					control={control}
					id={`markUp`}
					name={`markUp`}
					label='Rabat'
					suffix='%'
				/>
				<Button type='submit' classes={styles.submit}>
					Zapisz
				</Button>
			</form>
		</SectionContainer>
	);
};

export default GlobalCosts;
