import {ReportTypeEnum} from '@api/models/reportModels';
import * as yup from 'yup';

const currentDate = new Date().toISOString().split("T")[0];

const reportValidationSchema = yup.object({
	reportType: yup
		.number()
		.oneOf(Object.values(ReportTypeEnum) as number[], 'Niepoprawny typ')
		.required('Pole jest wymagane'),

	start: yup
		.string()
		.required('Data jest wymagana')
		.matches(/^\d{4}-\d{2}-\d{2}$/, 'Niepoprawny format (YYYY-MM-DD)')
		.test(
			'isValidDate',
			'Niepoprawna data',
			(value) => !isNaN(Date.parse(value))
		).test('isNotFutureDate', 'Data nie może być większa niż dzień aktualny', (value) => value <= currentDate),

	end: yup
		.string()
		.required('Data jest wymagana')
		.matches(/^\d{4}-\d{2}-\d{2}$/, 'Niepoprawny format (YYYY-MM-DD)')
		.test('isValidDate', 'Niepoprawna data', (value) => !isNaN(Date.parse(value)))
		.test(
			'isAfterStart',
			'Data zakończenia musi być większa lub równa dacie rozpoczęcia',
			function (value) {
				const {start} = this.parent;
				return Date.parse(value) >= Date.parse(start);
			}
		).test('isNotFutureDate', 'Data nie może być większa niż dzień aktualny', (value) => value <= currentDate)
});

export default reportValidationSchema;
