import {Text} from '@components/_Typography';
import SectionContainer from '@components/SectionContainer/SectionContainer';
import {getPreviewOffer} from '@selectors/previewOfferSelectors';
import React from 'react';
import {useSelector} from 'react-redux';
import styles from './UserData.module.scss';

const UserData = () => {
	const offerDetails = useSelector(getPreviewOffer);

	return (
		<SectionContainer title='Dane użytkownika'>
			<div className={styles.wrapper}>
				<Text>
					<strong>Imię i nazwisko</strong>
					<br />
					{offerDetails?.receiver}
				</Text>
				<Text>
					<strong>Nazwa firmy</strong>
					<br />
					{offerDetails?.company}
				</Text>
				<Text>
					<strong>E-mail</strong>
					<br />
					{offerDetails?.email}
				</Text>
				<Text>
					<strong>Telefon</strong>
					<br />
					{offerDetails?.phone}
				</Text>
			</div>
		</SectionContainer>
	);
};

export default UserData;
