import React, {useMemo} from 'react';
import SectionContainer from '@components/SectionContainer/SectionContainer';
import {useForm} from 'react-hook-form';
import FormInput from '@components/_useFormElements/FormInput/FormInput';
import Button from '@components/Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {getGlobalCosts} from '@selectors/adminDataSelectors';
import {yupResolver} from '@hookform/resolvers/yup';
import {CostsModel, GlobalCostsSchema} from './GlobalCostsSchema';
import styles from './GlobalCosts.module.scss';
import {GlobalCostsProps} from './GlobalCostsProps';
import {setAdminOfferCostsRequest} from '@api/offer';
import {setPreviewOffer} from '@actions/previewOfferActions';

const GlobalCosts: React.FC<GlobalCostsProps> = (props) => {
	const {defaultData, offerId} = props;
	const stateCosts = useSelector(getGlobalCosts);

	const dispatch = useDispatch();

	const defaultValuse: CostsModel = useMemo(
		() =>
			defaultData || {
				reserve: 0,
				transport: stateCosts?.transport || 0,
				discount: 0,
			},
		[defaultData, stateCosts?.transport]
	);
	const {control, handleSubmit} = useForm<CostsModel>({
		defaultValues: defaultValuse,
		resolver: yupResolver(GlobalCostsSchema),
	});

	const onSubmit = handleSubmit(async (data) => {
		if (offerId) {
			await setAdminOfferCostsRequest({
				id: offerId,
				transportCost: data.transport,
				otherCost: data.reserve,
				markUp: data.discount,
			}).then((res) => {
				dispatch(setPreviewOffer(res));
			});
		}
	});

	return (
		<SectionContainer title={'Edycja kosztów oferty'}>
			<form onSubmit={onSubmit} className={styles.form}>
				<FormInput
					control={control}
					id={`transport`}
					name={`transport`}
					label='Koszt transportu'
					suffix='zł'
				/>
				<FormInput
					control={control}
					id={`reserve`}
					name={`reserve`}
					label='Rezerwa'
					suffix='zł'
				/>
				<FormInput
					control={control}
					id={`discount`}
					name={`discount`}
					label='Rabat'
					type='number'
					suffix='%'
				/>
				<Button type='submit' classes={styles.submit}>
					Zapisz
				</Button>
			</form>
		</SectionContainer>
	);
};

export default GlobalCosts;
