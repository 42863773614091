import React, {useEffect, useState} from 'react';
import AppHeader from '@components/AppHeader/AppHeader';
import GlobalCosts from './components/GlobalCosts/GlobalCosts';
import {useDispatch} from 'react-redux';
import {getAdminCostsRequest} from '@api/adminData';
import {setGlobalCostsAction} from '@actions/adminDataActions';
import Loader from '@components/Loader/Loader';
import styles from './AdminData.module.scss';

const AdminData = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			setLoading(true);
			await getAdminCostsRequest().then((res) => dispatch(setGlobalCostsAction(res)));
			setLoading(false);
		})();
	}, [dispatch]);

	if (loading)
		return (
			<div className={styles.loadingWrapper}>
				<Loader />
			</div>
		);

	return (
		<>
			<AppHeader title='Dane administracyjne' text='' />
			<GlobalCosts />
		</>
	);
};

export default AdminData;
