import FormInput from '@components/_useFormElements/FormInput/FormInput';
import FormSelect from '@components/_useFormElements/FormSelect/FormSelect';
import Button from '@components/Button/Button';
import React, {useMemo} from 'react';
import {useForm} from 'react-hook-form';
import styles from './ReportForm.module.scss';
import {GetReportRequestModel, ReportTypeEnum} from '@api/models/reportModels';
import {yupResolver} from '@hookform/resolvers/yup';
import reportValidationSchema from './ReportFormSchema';
import {getReportRequest} from '@api/report';
import {useDispatch} from 'react-redux';
import {loadingAction} from '@actions/appActions';
import {ReportFormProps} from './ReportFormProps';

const reportOptions = [
	{value: ReportTypeEnum.Client, label: 'Użytkownicy'},
	{value: ReportTypeEnum.Brand, label: 'Marki'},
	{value: ReportTypeEnum.BodyType, label: 'Rodzaje zabudów'},
	{value: ReportTypeEnum.Vechicle, label: 'Pojazdy'},
	{value: ReportTypeEnum.VechicleList, label: 'Lista wersji pojazdów'},
];

const ReportForm = (props: ReportFormProps) => {
	const {saveCsvData} = props;

	const dispatch = useDispatch();

	const {handleSubmit, control, watch} = useForm<GetReportRequestModel>({
		defaultValues: {
			reportType: ReportTypeEnum.Client,
			start: new Date().toISOString().split('T')[0],
			end: new Date().toISOString().split('T')[0],
		},
		resolver: yupResolver(reportValidationSchema),
	});

	const onSubmit = handleSubmit(async (data) => {
		dispatch(loadingAction(true));
		const res = await getReportRequest(data);
		dispatch(loadingAction(false));
		saveCsvData(res);
	});

	const reportType = watch('reportType');

	const hideDates = useMemo(
		() => reportType === ReportTypeEnum.VechicleList,
		[reportType]
	);

	return (
		<form onSubmit={onSubmit} className={styles.wrapper}>
			<FormSelect
				control={control}
				name='reportType'
				label='Typ raportu'
				options={reportOptions}
			/>
			{!hideDates && (
				<>
					<FormInput
						control={control}
						id='start'
						name='start'
						label='Data od:'
						type='date'
					/>
					<FormInput
						control={control}
						id='end'
						name='end'
						label='Data do:'
						type='date'
					/>
				</>
			)}
			<Button type='submit'>Wygeneruj raport</Button>
		</form>
	);
};

export default ReportForm;
