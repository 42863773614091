// Actions
import {
	GetPropertiesCostsResponse,
	PropertyCostModel,
} from '@api/models/propertiesCostsModels';
import {
	PropertiesCostsPage,
	PropertiesCostsPageActions,
} from '@actions/propertiesCostsPageActions';

const initialState: PropertiesCostsPage = {
	properties: null,
	currentPage: 1,
	maxPage: 1,
};

export const propertiesCostsPageReducer = (
	state = initialState,
	action: {
		type: PropertiesCostsPageActions;
		payload:
			| GetPropertiesCostsResponse
			| PropertyCostModel[]
			| PropertyCostModel
			| number;
	}
): PropertiesCostsPage => {
	switch (action.type) {
		case PropertiesCostsPageActions.SET_PROPERTIES:
			return {
				...state,
				properties: (action.payload as GetPropertiesCostsResponse).attributeList,
				maxPage: (action.payload as GetPropertiesCostsResponse).totalPages,
				currentPage: (action.payload as GetPropertiesCostsResponse).pageIndex,
			};
		case PropertiesCostsPageActions.SET_PROPERTIES_LIST:
			return {
				...state,
				properties: action.payload as PropertyCostModel[],
			};
		case PropertiesCostsPageActions.SET_PROPERTIES_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case PropertiesCostsPageActions.SET_PROPERTIES_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
