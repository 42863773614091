import React, {useMemo} from 'react';
import {
	Document,
	Image,
	Page,
	View,
	Text,
	StyleSheet,
	PDFViewer,
	Font,
} from '@react-pdf/renderer';
import {useSelector} from 'react-redux';
import {getPreviewOffer} from '@selectors/previewOfferSelectors';
import Table from './components/Table';
import font from '@fonts/Roboto-Regular.ttf';
import fontBold from '@fonts/Roboto-Bold.ttf';
import Header from './components/Header';

Font.register({
	family: 'Roboto',
	fonts: [
		{
			src: fontBold,
			fontWeight: 'bold',
		},
		{
			src: font,
			fontWeight: 'normal',
		},
	],
});

const DocumentPdf = () => {
	const currentOffer = useSelector(getPreviewOffer);

	const styles = StyleSheet.create({
		page: {
			padding: '20px 35px',
			fontFamily: 'Roboto',
			color: '#005179',
			fontSize: 12,
		},
		headerText: {
			fontSize: 9,
			opacity: 0.75,
		},
		userInfoWrapper: {
			marginTop: 10,
			marginBottom: 20,
			fontSize: 12,
			border: '1px solid #00BBEE',
		},
		description: {
			fontSize: 12,
			marginBottom: 30,
		},
		userInfoRow: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap',
		},
		borderBottom: {
			borderBottom: '1px solid #00BBEE',
		},
		borderRight: {
			borderRight: '1px solid #00BBEE',
		},
		userInfoCol: {
			width: 'calc(100% / 3)%',
			padding: '5px 10px',
		},
		mainTitle: {
			fontSize: 16,
			textAlign: 'center',
			fontWeight: 'bold',
			margin: '10px 20px 20px',
		},
		sectionTitle: {
			fontSize: 16,
			fontWeight: 'bold',
			margin: '30px 0 10px',
		},
		pageNumber: {
			position: 'absolute',
			fontSize: 9,
			bottom: 20,
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey',
		},
		descriptionBold: {
			fontWeight: 'bold',
		},
	});

	const createDate = useMemo(
		() =>
			currentOffer ? new Date(currentOffer?.createDate).toLocaleString() : '',
		[currentOffer]
	);

	const sizes = useMemo(
		() => ({
			width: currentOffer?.width,
			height: currentOffer?.height,
			length: currentOffer?.lenght,
		}),
		[currentOffer]
	);

	return (
		<PDFViewer style={{width: '100%', height: '90vh'}}>
			<Document>
				<Page style={styles.page} size='A4'>
					<Header />
					<View>
						<Text style={styles.headerText}>Data: {createDate}</Text>
						{/* User details Start*/}
						<View style={styles.userInfoWrapper}>
							<View
								style={{
									...styles.userInfoRow,
									...styles.borderBottom,
									fontWeight: 'bold',
								}}>
								<View style={{...styles.userInfoCol, ...styles.borderRight}}>
									<Text>Pan/Pani</Text>
								</View>
								<View style={{...styles.userInfoCol, ...styles.borderRight}}>
									<Text>Firma</Text>
								</View>
								<View style={styles.userInfoCol}>
									<Text>Kontakt</Text>
								</View>
							</View>
							<View style={styles.userInfoRow}>
								<View style={{...styles.userInfoCol, ...styles.borderRight}}>
									<Text>{currentOffer?.receiver}</Text>
								</View>
								<View style={{...styles.userInfoCol, ...styles.borderRight}}>
									<Text>{!!currentOffer?.company ? currentOffer?.company : '-'}</Text>
								</View>
								<View style={styles.userInfoCol}>
									<Text>email: {currentOffer?.email}</Text>
									<Text>tel.: {!!currentOffer?.phone ? currentOffer?.phone : '-'}</Text>
								</View>
							</View>
						</View>
						{/* User details End*/}
					</View>
					<Text style={styles.mainTitle}>
						Zabudowa {currentOffer?.bodyType} na bazie samochodu {currentOffer?.brand}{' '}
						{currentOffer?.model} {currentOffer?.chassis}
					</Text>

					<View
						style={{
							...styles.userInfoWrapper,
							width: '50%',
							marginHorizontal: 'auto',
						}}>
						<Image
							src={
								!!currentOffer?.vechicleBodyImg
									? currentOffer?.vechicleBodyImg
									: !!currentOffer?.cabinImg
									? currentOffer?.cabinImg
									: currentOffer?.carBodyImg ?? ''
							}
						/>
					</View>

					<Text style={styles.description}>{currentOffer?.vechicleDescription}</Text>

					{!!currentOffer?.offerItems.length && (
						<>
							<Text style={styles.mainTitle}>Zamówione opcje</Text>
							<View>
								{currentOffer?.offerItems.map((property) => (
									<Text style={{...styles.description, marginBottom: 0}}>
										<Text style={styles.descriptionBold}>{property.attributeName}</Text> -{' '}
										{property.attributeDescription}
									</Text>
								))}
							</View>
						</>
					)}
					{!!currentOffer?.vechicleImg && (
						<View
							style={{
								...styles.userInfoWrapper,
								width: '50%',
								marginHorizontal: 'auto',
							}}>
							<Image src={currentOffer?.vechicleImg} />
						</View>
					)}
					<Text
						style={styles.pageNumber}
						render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
						fixed
					/>
				</Page>
				<Page style={styles.page} size='A4'>
					<Header />
					<Table
						title='Zabudowa bazowa'
						items={[
							{name: 'Waga', value: `${currentOffer?.baseWeight} kg`},
							{name: 'Cena (netto)', value: `${currentOffer?.vechiclePrice} zł`},
						]}
					/>
					{!!sizes.height && !!sizes.length && !!sizes.width && (
						<Table
							title='Wymiary wewnętrzne'
							items={[
								{name: 'Szerokość', value: `${sizes.width} mm`},
								{name: 'Wysokość', value: `${sizes.height} mm`},
								{name: 'Długość', value: `${sizes.length} mm`},
							]}
						/>
					)}
					{!!currentOffer?.offerItems.length && (
						<Table
							title='Doposażenie'
							items={[
								{
									name: <Text style={{fontWeight: 'bold', fontSize: 9}}>Nazwa</Text>,
									value: (
										<Text style={{fontWeight: 'bold', fontSize: 9}}>
											Cena cennikowa (netto)
										</Text>
									),
									value2: <Text style={{fontWeight: 'bold', fontSize: 9}}>Waga</Text>,
								},
								...currentOffer.offerItems
									.slice() // Creates a shallow copy to avoid mutating the original array
									.sort((a, b) => Number(a.isEuroPrice) - Number(b.isEuroPrice)) // Sort: `isEuroPrice` items to the end
									.map((item) => ({
										name: item.attributeName,
										value: `${item.price} ${item.isEuroPrice ? '€' : 'zł'}`,
										value2: `${item.weight} kg`,
									})),
							]}
						/>
					)}
					<Text
						style={styles.pageNumber}
						render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
						fixed
					/>
				</Page>
				<Page style={styles.page} size='A4'>
					<Header />
					<View>
						<Text style={styles.sectionTitle}>Podsumowanie</Text>
						<Table
							items={[
								{
									name: 'Aktualność wyceny',
									value: `7 dni`,
								},
								{
									name: 'Auto bazowe',
									value: `${currentOffer?.brand} ${currentOffer?.model} ${currentOffer?.chassis}`,
								},
								{
									name: 'Warunki gwarancji',
									value: `24 miesiące (dotyczy zabudowy)`,
								},
								{
									name: 'Planowany termin dostarczenia pojazdu bazowego do zabudowy',
									value: `do 3 miesięcy`,
								},
								{
									name: 'Cena całkowita (netto)',
									value: `${currentOffer?.totalPrice} zł ${
										!!currentOffer?.totalPriceEuro
											? `+ ${currentOffer?.totalPriceEuro} €`
											: ''
									}`,
								},
								{
									name: 'Waga całkowita',
									value: `${currentOffer?.totalWeight} kg`,
								},
							]}
						/>
					</View>

					{/* <Text style={styles.sectionTitle}>Zamówienie</Text>

					<Text style={{marginBottom: 30}}>
						Działając w imieniu Zamawiającego, jako osoba upoważniona do składania
						zamówień, oświadczam, że przyjmuję bez zastrzeżeń Wycenę Gruau Polska Sp.
						z o.o. w Daszynie z dnia {createDate} i składam zamówienie zgodnie z
						powyższą Wyceną na wykonanie zabudowy.
					</Text> */}
					{/* sign Start*/}
					{/* <View style={styles.userInfoWrapper}>
						<View
							style={{
								...styles.userInfoRow,
								...styles.borderBottom,
								fontWeight: 'bold',
							}}>
							<View style={{...styles.userInfoCol, ...styles.borderRight}}>
								<Text> </Text>
								<Text> </Text>
								<Text> </Text>
							</View>
							<View style={{...styles.userInfoCol, ...styles.borderRight}}>
								<Text> </Text>
							</View>
							<View style={styles.userInfoCol}>
								<Text> </Text>
							</View>
						</View>
						<View style={styles.userInfoRow}>
							<View style={{...styles.userInfoCol, ...styles.borderRight}}>
								<Text>Dane Zamawiającego (nazwa, adres, NIP, KRS)</Text>
							</View>
							<View style={{...styles.userInfoCol, ...styles.borderRight}}>
								<Text>Imię i nazwisko osoby składającej zamówienie</Text>
							</View>
							<View style={styles.userInfoCol}>
								<Text>Data i podpis osoby upoważnionej do złożenia zamówienia z pieczęcią, data.</Text>
							</View>
						</View>
					</View> */}
					{/* sign End*/}
					<Text
						style={styles.pageNumber}
						render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
						fixed
					/>
				</Page>
			</Document>
		</PDFViewer>
	);
};

export default DocumentPdf;
