import {UnknownAction} from '@reduxjs/toolkit';
import {Dispatch} from 'react';
import {GetPropertiesCostsResponse} from './models/propertiesCostsModels';
import {getFilterParams} from '@helpers/getFilterParams';
import {requestCreator} from '@helpers/requsetCreator';
import {setPropertiesCosts} from '@actions/propertiesCostsPageActions';
import {UpdatePropertiesCostsData} from './models/propertiesModels';

export const getPropertiesCostsRequest = async (
	dispatch: Dispatch<UnknownAction> | false,
	pageIndex: number,
	pageSize: number,
	filters?: {categoryId?: number; vechicleId?: number}
): Promise<GetPropertiesCostsResponse> => {
	let url = `/costs/attributes?pageIndex=${pageIndex}&pageSize=${pageSize}`;

	const res = await requestCreator<undefined, GetPropertiesCostsResponse>(
		'GET',
		getFilterParams(filters, url)
	);

	if (dispatch) {
		dispatch(setPropertiesCosts(res));
	}

	return res;
};

export const updatePropertiesCostsRequest = async (
	data: UpdatePropertiesCostsData
): Promise<any> => {
	await requestCreator<UpdatePropertiesCostsData, any>(
		'POST',
		`/costs/edit-attributes`,
		data,
		undefined,
		'Dane zaktualizowane poprawnie'
	);
};
