// Libraries
import classNames from 'classnames';
// Types
import {ReactComponent as ErrorSvg} from '@assets/icons/error-svg.svg';
import FormInputProps from './FormInputProps';
import styles from './FormInput.module.scss';
import {Controller} from 'react-hook-form';
import {getFieldValueByDynamicKey} from '@helpers/objectHelper';
import Info from '@components/Info/Info';

const FormInput = (props: FormInputProps) => {
	const {
		id,
		type = 'text',
		name,
		label,
		as = 'input',
		required = false,
		placeholder,
		classes,
		isDisabled,
		readonly,
		control,
		onBlur,
		info,
		suffix,
	} = props;

	const Tag = as;
	return (
		<Controller
			name={name}
			control={control}
			render={(e: any) => {
				const errors = e.formState.errors;
				var error = {message: null};
				if (Object.keys(errors).length > 0) {
					error = getFieldValueByDynamicKey(errors, name);
				}
				return (
					<div
						className={classNames('input', classes)}
						data-status={!!error?.message ? 'error' : ''}>
						<label
							className={classNames(
								'input__label',
								{[styles.label]: info},
								styles.wrapper
							)}>
							{label && (
								<span className='input__label-text'>
									{label} {required && '*'}
								</span>
							)}
							<div className='input__label'>
								<Tag
									key={name}
									ref={e.field.ref}
									id={id}
									readOnly={readonly}
									className={classNames('input__field', styles.input)}
									type={as === 'textarea' ? undefined : type}
									style={{
										height: type === 'color' ? 50 : 'auto',
										padding: type === 'color' ? '0 20px' : undefined,
									}}
									onChange={e.field.onChange}
									onBlur={onBlur}
									placeholder={placeholder}
									autoComplete='true'
									disabled={isDisabled}
									value={e.field.value}
								/>
								{suffix && <div className={styles.suffix}>{suffix}</div>}
								{info && <Info id={id} content={info} />}
								{!!error?.message && (
									<Info id={`error-${id}`} content={error.message} icon={<ErrorSvg />} />
								)}
							</div>
						</label>
					</div>
				);
			}}
		/>
	);
};

export default FormInput;
