import {FormField, FormKeys} from '@commonTypes/main';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import Select from '@components/_FormElements/Select/Select';
import Button, {ButtonVariant} from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './Filters.module.scss';
import {safetyAction} from '@helpers/safetyAction';

interface FilterElement {
	name: string;
	label: string;
	as?: 'input';
	type?: 'date';
	options?: {value: string; name: string}[];
}

interface FiltersProps {
	filters: FilterElement[];
	compareForm?: () => boolean;
}

const Filters = (props: FiltersProps) => {
	const {filters, compareForm} = props;

	const [modal, setModal] = useState(false);
	const [initState, setInitState] = useState<FormKeys<any>>({});
	const [form, setForm] = useState<FormKeys<any>>({});
	const navigate = useNavigate();

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);

		const dynamicInitialState = filters.reduce((acc, filter) => {
			const queryValue = queryParams.getAll(filter.name).length
				? queryParams.getAll(filter.name)
				: [];

			acc[filter.name] = {
				...defaultInput,
				value: queryValue,
				required: false,
			};
			return acc;
		}, {} as FormKeys<any>);

		setForm(dynamicInitialState);
		setInitState(dynamicInitialState);
	}, [filters]);

	const updateFormHandler = (name: string, value: FormField) => {
		setForm((prevForm) => ({
			...prevForm,
			[name]: value,
		}));
	};

	const closeHandler = useCallback(() => {
		setForm(initState);
		setModal(false);
	}, [initState]);

	const submitHandler = useCallback(() => {
		const queryParams = new URLSearchParams();

		for (const key in form) {
			const selectedValues = form[key]?.value;

			const currentFilter = filters.find((filter) => filter.name === key);
			const isInput = currentFilter?.as === 'input';

			if (isInput && !!selectedValues) {
				queryParams.append(
					key,
					currentFilter?.type === 'date' ? selectedValues : selectedValues
				);
			} else if (!isInput && selectedValues && selectedValues.length > 0) {
				selectedValues.forEach((value: string) => queryParams.append(key, value));
			}
		}

		navigate(`?${queryParams.toString()}`);
		setModal(false);
	}, [filters, form, navigate]);

	const onSearch = useCallback(() => {
		safetyAction(submitHandler, compareForm);
	}, [compareForm, submitHandler]);

	const clearFiltersHandler = useCallback(() => {
		const clearedForm = filters.reduce((acc, filter) => {
			acc[filter.name] = {
				...defaultInput,
				value: [],
				required: false,
			};
			return acc;
		}, {} as FormKeys<any>);

		setForm(clearedForm);
		navigate(window.location.pathname);
		setModal(false);
	}, [filters, navigate]);

	const onClear = useCallback(() => {
		safetyAction(clearFiltersHandler, compareForm);
	}, [clearFiltersHandler, compareForm]);

	const hasActiveFilters = useMemo(() => {
		const queryParams = new URLSearchParams(window.location.search);
		return filters.some((filter) => {
			const queryValue = queryParams.get(filter.name);
			return queryValue && queryValue.length > 0;
		});
	}, [filters]);

	return (
		<>
			<div className={styles.buttons}>
				{hasActiveFilters && (
					<Button type='button' variant={ButtonVariant.Outline} onClick={onClear}>
						Wyczyść filtry
					</Button>
				)}
				<Button
					type='button'
					variant={ButtonVariant.OutlineWithBorder}
					onClick={() => setModal(true)}>
					Filtry
				</Button>
			</div>

			{modal && (
				<Modal title={'Filtry'} closeHandler={closeHandler}>
					{filters.map((filter, index) => {
						if (filter.as === 'input')
							return (
								<Input
									key={filter.name}
									id={filter.name}
									name={filter.name}
									label={filter.label}
									value={form?.[filter.name]?.value}
									errorMessage={''}
									type={filter.type}
									valueChangeHandler={updateFormHandler}
									required={false}
								/>
							);

						if (filter.as !== 'input' && !!!filter.options)
							throw new Error('Options are required');
						return (
							<Select<string | undefined>
								key={filter.name}
								id={filter.name}
								name={filter.name}
								label={filter.label}
								value={form?.[filter.name]?.value}
								errorMessage={form?.[filter.name]?.errorMessage}
								valueChangeHandler={updateFormHandler}
								defaultOption={{value: '0', label: 'Wybierz'}}
								options={filter.options?.map((option) => ({
									value: option.value,
									label: option.name,
								}))}
								required={false}
								multiselect
								zIndex={filters.length - index}
							/>
						);
					})}
					<div className={styles.buttons}>
						<Button
							type='button'
							variant={ButtonVariant.OutlineWithBorder}
							onClick={closeHandler}>
							Zamknij
						</Button>
						<Button type='button' onClick={onSearch}>
							Szukaj
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
};

export default Filters;
