import React from 'react';
import {ReactComponent as PDFSvg} from '@assets/icons/pdf-svg.svg';
import {Text} from '@components/_Typography';
import {FilePDFProps} from './FilePDFProps';
import styles from './FilePDF.module.scss';

const FilePDF = (props: FilePDFProps) => {
	const {text} = props;

	return (
		<div className={styles.wrapper}>
			<PDFSvg />
			<Text>{text}</Text>
		</div>
	);
};

export default FilePDF;
