import {
	GetPropertiesCostsResponse,
	PropertyCostModel,
} from '@api/models/propertiesCostsModels';

export interface PropertiesCostsPage {
	properties: PropertyCostModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum PropertiesCostsPageActions {
	SET_PROPERTIES = 'SET_PROPERTIES',
	SET_PROPERTIES_LIST = 'SET_PROPERTIES_LIST',
	SET_PROPERTIES_CURRENT_PAGE = 'SET_PROPERTIES_CURRENT_PAGE',
	SET_PROPERTIES_MAX_PAGE = 'SET_PROPERTIES_MAX_PAGE',
}

const setPropertiesCosts = (properties: GetPropertiesCostsResponse) => ({
	type: PropertiesCostsPageActions.SET_PROPERTIES,
	payload: properties,
});

const setPropertiesCostsList = (properties: PropertyCostModel[]) => ({
	type: PropertiesCostsPageActions.SET_PROPERTIES_LIST,
	payload: properties,
});

const setPropertiesCostsCurrentPage = (currentPage: number) => ({
	type: PropertiesCostsPageActions.SET_PROPERTIES_CURRENT_PAGE,
	payload: currentPage,
});

const setPropertiesCostsMaxPage = (maxPage: number) => ({
	type: PropertiesCostsPageActions.SET_PROPERTIES_MAX_PAGE,
	payload: maxPage,
});

export {
	setPropertiesCosts,
	setPropertiesCostsCurrentPage,
	setPropertiesCostsMaxPage,
	setPropertiesCostsList,
};
