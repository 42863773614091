// Actions
import {AdminDataActions, AdminDataPageModel} from '@actions/adminDataActions';

const initialState: AdminDataPageModel = {
	globalCosts: null,
};

export const adminDataReducer = (
	state = initialState,
	action: {type: AdminDataActions; payload: any}
): AdminDataPageModel | null => {
	switch (action.type) {
		case AdminDataActions.SET_COSTS:
			return {...state, globalCosts: action.payload};
		default:
			return state;
	}
};
