import {GetAdminCostsResponse} from '@api/models/adminDataModels';

export interface AdminDataPageModel {
	globalCosts: GetAdminCostsResponse | null;
}

export enum AdminDataActions {
	SET_COSTS = 'SET_COSTS',
}

const setGlobalCostsAction = (globalCosts: GetAdminCostsResponse) => ({
	type: AdminDataActions.SET_COSTS,
	payload: globalCosts,
});

export {setGlobalCostsAction};
