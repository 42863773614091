import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, useParams} from 'react-router-dom';
// API
import {getAdminOfferDetailsRequest} from '@api/offer';
// Redux
import {loadingAction} from '@actions/appActions';
import DocumentPdf from '@components/DocumentPdf/DocumentPdf';
import {clearPreviewOffer, setPreviewOffer} from '@actions/previewOfferActions';
import AppHeader from '@components/AppHeader/AppHeader';
import {getPreviewOffer} from '@selectors/previewOfferSelectors';
import UserData from './components/UserData/UserData';
import {getAppLoading} from '@selectors/appSelectors';
import VechicleData from './components/VechicleData/VechicleData';
import PropertiesData from './components/PropertiesData/PropertiesData';
import SectionContainer from '@components/SectionContainer/SectionContainer';
import ConfigurationData from './components/ConfigurationData/ConfigurationData';
import styles from './PreviewOffer.module.scss';
import GlobalCosts from './components/GlobalCosts/GlobalCosts';

const PreviewOffer = () => {
	const {id} = useParams();

	const [error, setError] = useState<boolean>(false);

	const dispatch = useDispatch();
	const loading = useSelector(getAppLoading);

	const offerDetails = useSelector(getPreviewOffer);

	useEffect(() => {
		(async () => {
			if (!!!id) return;
			dispatch(loadingAction(true));
			await getAdminOfferDetailsRequest(id)
				.then((res) => dispatch(setPreviewOffer(res)))
				.catch(() => setError(true));
			dispatch(loadingAction(false));
		})();

		return () => {
			dispatch(clearPreviewOffer());
		};
	}, [dispatch, id]);

	if (error) return <Navigate to='/404' replace />;

	if (loading) return <></>;

	return (
		<>
			<AppHeader title={`Oferta ${offerDetails?.offerName || id}`} text='' />
			<main className={styles.wrapper}>
				<UserData />
				<GlobalCosts
					offerId={id !== undefined ? +id : undefined}
					defaultData={{
						transport: offerDetails?.transportCost || 0,
						reserve: offerDetails?.otherCost || 0,
						discount: offerDetails?.markUp || 0,
					}}
				/>
				<VechicleData />
				<PropertiesData />
				<ConfigurationData />
				<SectionContainer title='Oferta dla klienta'>
					<DocumentPdf />
				</SectionContainer>
			</main>
		</>
	);
};

export default PreviewOffer;
