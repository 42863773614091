import React from 'react';
import {ReportDownloadProps} from './ReportDownloadProps';
import Button from '@components/Button/Button';
import SectionContainer from '@components/SectionContainer/SectionContainer';
import FilePDF from '@components/FilePDF/FilePDF';
import styles from './ReportDownload.module.scss';

const ReportDownload = (props: ReportDownloadProps) => {
	const {downloadCsv, fileName} = props;

	return (
		<SectionContainer title={`Wygenerowano raport ${fileName}`}>
			<div className={styles.wrapper}>
				<FilePDF text={fileName} />
				<Button type='button' onClick={downloadCsv}>
					Pobierz
				</Button>
			</div>
		</SectionContainer>
	);
};

export default ReportDownload;
