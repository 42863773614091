// Libraries
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import deepEqual from 'fast-deep-equal';
import {diff} from 'deep-diff';
// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
// API
// Assets
import {ReactComponent as Arrow} from '@assets/icons/arrow.svg';
// Types
import {Tag} from '@commonTypes/tags';
import Pagination from '@components/Pagination/Pagination';
import Filters from '@components/Filters/Filters';
import {useFieldArray, useForm, useWatch} from 'react-hook-form';
import FormInput from '@components/_useFormElements/FormInput/FormInput';
import Button from '@components/Button/Button';
import {toast} from 'react-toastify';
import {getConstItems} from '@helpers/getCostsItems';
import {VechicleCostFormModel} from './VechiclesCostList.type';
import styles from './VechiclesCostList.module.scss';
import {yupResolver} from '@hookform/resolvers/yup';
import FormSchema from './VechiclesCostListSchema';
import {loadingAction} from '@actions/appActions';
import {omitProps} from '@helpers/omitProps';
import {
	getVechiclesCostsPageMaxPage,
	getVechiclesCostsPageVechicles,
} from '@selectors/vechiclesCostsPageSelectors';
import {
	getVechiclesCostsRequest,
	updateVechiclesCostsRequest,
} from '@api/vechiclesCosts';
import {setVechiclesCostsList} from '@actions/vechiclesCostsPageActions';
import {getBrandsRequest} from '@api/brands';
import {getBodiesRequset} from '@api/body';
import {BodyModel} from '@api/models/bodyModels';
import {BrandModel} from '@api/models/brandsModels';

const VechiclesCostList = () => {
	const [allBrands, setAllBrands] = useState<BrandModel[]>([]);
	const [allBodies, setAllBodies] = useState<BodyModel[]>([]);
	const storeVechicles = useSelector(getVechiclesCostsPageVechicles);
	const vechicles = useMemo(
		() => storeVechicles?.map((property) => ({...property, isToggled: false})),
		[storeVechicles]
	);
	const dispatch = useDispatch();

	const [defaultState, setDefaultState] = useState<{
		costs: VechicleCostFormModel[];
	}>({costs: []});
	const {control, getValues, reset, setValue, watch, handleSubmit} = useForm<{
		costs: VechicleCostFormModel[];
	}>({
		defaultValues: {costs: vechicles || []},
		resolver: yupResolver(FormSchema),
	});
	const {fields} = useFieldArray({
		name: 'costs',
		control,
	});

	useEffect(() => {
		if (vechicles) {
			reset({costs: vechicles});
			setDefaultState({costs: vechicles});
		}
	}, [vechicles, reset]);

	useEffect(() => {
		(async () => {
			const [brands, bodies] = await Promise.all([
				getBrandsRequest(),
				getBodiesRequset(),
			]);
			setAllBrands(brands);
			setAllBodies(bodies);
		})();
	}, []);

	const toggledValues = useWatch({
		control,
		name: 'costs',
	})?.map((item) => item?.isToggled);

	const tableElements = useMemo(
		() =>
			fields
				?.map((item, index) => {
					if (!!item) {
						const moreSizes = item.dimensions.length > 1;
						const mainElement = {
							toggler: moreSizes ? (
								<button
									type='button'
									onClick={() => {
										setValue(
											`costs.${index}.isToggled`,
											!getValues(`costs.${index}.isToggled`)
										);
									}}
									className={styles.toggle}
									data-toggled={!watch(`costs.${index}.isToggled`)}>
									<Arrow />
								</button>
							) : (
								<></>
							),
							name: `${item.brand} ${item.model}${
								item.cabin ? ' ' + item.cabin : ''
							} ${item.carBody} ${item.chassis}`,
							price: moreSizes ? (
								<></>
							) : (
								<FormInput
									classes={styles.input}
									control={control}
									id={`costs.${index}.dimensions.0.price`}
									name={`costs.${index}.dimensions.0.price`}
									label=''
									suffix='zł'
								/>
							),
							cost: moreSizes ? (
								<></>
							) : (
								<FormInput
									classes={styles.input}
									control={control}
									id={`costs.${index}.dimensions.0.cost`}
									name={`costs.${index}.dimensions.0.cost`}
									label=''
									suffix='zł'
								/>
							),
						};

						const sizes = moreSizes
							? item.dimensions.map((vechicle, vechicleIndex) => ({
									isHidden: !watch(`costs.${index}.isToggled`),
									toggler: <></>,
									name: (
										<span
											style={{
												display: 'block',
												paddingLeft: 40,
											}}>{`Długość: ${vechicle.lenght}`}</span>
									),
									price: (
										<FormInput
											classes={styles.input}
											control={control}
											id={`costs.${index}.dimensions.${vechicleIndex}.price`}
											name={`costs.${index}.dimensions.${vechicleIndex}.price`}
											label=''
											suffix='zł'
										/>
									),
									cost: (
										<FormInput
											classes={styles.input}
											control={control}
											id={`costs.${index}.dimensions.${vechicleIndex}.cost`}
											name={`costs.${index}.dimensions.${vechicleIndex}.cost`}
											label=''
											suffix='zł'
										/>
									),
							  }))
							: [];

						return item.dimensions?.length ? [mainElement, ...sizes] : mainElement;
					}
					return undefined;
				})
				.flat()
				.filter((item) => !!item),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[control, fields, getValues, setValue, toggledValues, watch]
	);

	const compareChanges = useCallback(() => {
		const ignoreProps = ['isToggled'];
		const defaultForm = omitProps(defaultState, ignoreProps);
		const currentForm = omitProps(getValues(), ignoreProps);
		return deepEqual(defaultForm, currentForm);
	}, [defaultState, getValues]);

	const getChanges = useCallback(() => {
		return diff(defaultState, getValues());
	}, [defaultState, getValues]);

	const submitHandler = useCallback(async () => {
		const changes = getChanges();

		if (!!!changes) return toast.info('Nie wykryto zmian w formularzu.');

		dispatch(loadingAction(true));

		let vechicleCosts: any[] = [];

		changes.forEach(({path}) => {
			if (path?.length !== 5) return;
			const attribute = path
				.slice(0, 4)
				.join('.') as `costs.${number}.dimensions.${number}`;

			vechicleCosts.push(getConstItems(attribute, getValues));
		});

		await updateVechiclesCostsRequest({vechicleCosts}).then(() => {
			dispatch(setVechiclesCostsList(getValues('costs')));
		});

		dispatch(loadingAction(false));
	}, [dispatch, getChanges, getValues]);

	return (
		<Container as={Tag.Section} classes={styles.wrapper}>
			<Filters
				compareForm={compareChanges}
				filters={[
					{
						name: 'vechicleName',
						label: 'Wyszukiwarka',
						as: 'input',
					},
					{
						name: 'brandIds',
						label: 'Marki',
						options: allBrands.map((brand) => ({
							value: `${brand.id}`,
							name: brand.name,
						})),
					},
					{
						name: 'bodyTypeIds',
						label: 'Rodzaj zabudowy',
						options: allBodies.map((body) => ({
							value: `${body.id}`,
							name: `${body.name}`,
						})),
					},
				]}
			/>
			<form onSubmit={handleSubmit(submitHandler)}>
				<Table
					headers={[
						{text: ''},
						{text: ''},
						{text: 'Cena', sortName: 'price'},
						{text: 'Koszt', sortName: 'cost'},
					]}
					elements={tableElements ?? []}
					groups={[
						{width: '1%'},
						{width: '49%', minWidth: '200px'},
						{width: '25%', minWidth: '90px'},
						{width: '25%', minWidth: '90px'},
					]}
				/>
				<Button type='submit' classes={styles.submit}>
					Zapisz zmiany
				</Button>
			</form>
			<Pagination
				beforeNavigate={compareChanges}
				request={getVechiclesCostsRequest}
				getMaxPages={getVechiclesCostsPageMaxPage}
			/>
		</Container>
	);
};

export default VechiclesCostList;
