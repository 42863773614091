import * as yup from 'yup';

export interface CostsModel {
	reserve: number;
	transport: number;
	discount: number;
}

export const GlobalCostsSchema = yup.object().shape({
	reserve: yup
		.number()
		.typeError('Wartość musi być liczbą.')
		.required('Cena jest wymagana')
		.min(0, 'Cena musi być liczbą nieujemną'),
	transport: yup
		.number()
		.typeError('Wartość musi być liczbą.')
		.required('Koszt jest wymagany')
		.min(0, 'Koszt musi być liczbą nieujemną'),
	discount: yup
		.number()
		.typeError('Wartość musi być liczbą.')
		.required('Koszt jest wymagany')
		.min(0, 'Koszt musi być liczbą nieujemną'),
});
