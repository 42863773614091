// Helpers
import {requestCreator} from '@helpers/requsetCreator';
import qs from 'qs';
// Types
import {GetReportRequestModel} from './models/reportModels';

export const getReportRequest = async (
	data: GetReportRequestModel
): Promise<any> => {
	const query = qs.stringify(data);
	return await requestCreator<undefined, any>('GET', `/raport?${query}`);
};
