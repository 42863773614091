import SectionContainer from '@components/SectionContainer/SectionContainer';
import Table from '@components/Table/Table';
import {getPreviewOffer} from '@selectors/previewOfferSelectors';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

const PropertiesData = () => {
	const offerDetails = useSelector(getPreviewOffer);

	const elements = useMemo(() => {
		if (!!!offerDetails?.offerItems.length) return [];

		return offerDetails?.offerItems.map((item) => ({
			id: item.id,
			name: item.attributeName,
			price: `${item.price} ${item.isEuroPrice ? '€' : 'zł'}`,
			cost: `${item.cost} ${item.isEuroPrice ? '€' : 'zł'}`,
			profit: `${item.profit} ${item.isEuroPrice ? '€' : 'zł'}`,
		}));
	}, [offerDetails?.offerItems]);

	if (!!!offerDetails?.offerItems.length) return <></>;

	return (
		<SectionContainer title='Cechy konfiguracji'>
			<Table
				headers={[
					{text: 'ID'},
					{text: 'Nazwa'},
					{text: 'Cena'},
					{text: 'Koszt'},
					{text: 'Zysk'},
				]}
				elements={elements}
				groups={[
					{width: '1%'},
					{width: '61.5%'},
					{width: '12.5%'},
					{width: '12.5%'},
					{width: '12.5%'},
				]}
			/>
		</SectionContainer>
	);
};

export default PropertiesData;
