import SectionContainer from '@components/SectionContainer/SectionContainer';
import Table from '@components/Table/Table';
import {getPreviewOffer} from '@selectors/previewOfferSelectors';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

const VechicleData = () => {
	const offerDetails = useSelector(getPreviewOffer);

	const elements = useMemo(() => {
		if (!!!offerDetails) return [];

		return [
			{
				name: `${offerDetails.brand} ${offerDetails.model} ${
					offerDetails.bodyType
				} ${offerDetails.cabin ?? ''} ${offerDetails.chassis} ${
					!!offerDetails.lenght ? `- ${offerDetails.lenght} mmm` : ''
				}`,
				basePrice: `${offerDetails.vechiclePrice} zł`,
				costs: `${offerDetails.vechicleCost} zł`,
				profit: `${offerDetails.vechiclePrice - offerDetails.vechicleCost} zł`,
			},
		];
	}, [offerDetails]);

	return (
		<SectionContainer title='Pojazd bazowy'>
			<Table
				headers={[
					{text: 'Nazwa'},
					{text: 'Cena bazowa'},
					{text: 'Koszta bazowe'},
					{text: 'Zysk'},
				]}
				elements={elements}
				groups={[{width: '52%'}, {width: '18%'}, {width: '18%'}, {width: '12%'}]}
			/>
		</SectionContainer>
	);
};

export default VechicleData;
