export const omitProps = <T extends Record<string, any>>(obj: T, propsToOmit: string[]): T => {
  if (!obj || typeof obj !== "object") return obj;

  if (Array.isArray(obj)) {
    return obj.map((item) => omitProps(item, propsToOmit)) as unknown as T;
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!propsToOmit.includes(key)) {
      (acc as Record<string, any>)[key] = omitProps(value, propsToOmit);
    }
    return acc;
  }, {} as T);
};
