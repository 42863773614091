// Types
import {Store} from '@reducers/rootReducer';

export const getVechiclesCostsPage = (state: Store) => state.vechiclesCostsPage;

export const getVechiclesCostsPageVechicles = (state: Store) =>
	state.vechiclesCostsPage.vechicles;

export const getVechiclesCostsPageMaxPage = (state: Store) =>
	state.vechiclesCostsPage.maxPage;

export const getVechiclesCostsPageCurrentPage = (state: Store) =>
	state.vechiclesCostsPage.currentPage;
