// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
// Types
import {Tag} from '@commonTypes/tags';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo, useState} from 'react';
import {
	getVehiclesPageMaxPage,
	getVehiclesPageVehicles,
} from '@selectors/vehiclesPageSelectors';
import {getVehiclesRequest} from '@api/vehicles';
import Pagination from '@components/Pagination/Pagination';
import Filters from '@components/Filters/Filters';
import {getBrandsRequest} from '@api/brands';
import {BrandModel} from '@api/models/brandsModels';
import {loadingAction} from '@actions/appActions';
import {getBodiesRequset} from '@api/body';
import {BodyModel} from '@api/models/bodyModels';
import StatusModal from '../StatusModal/StatusModal';
import {VehicleDetailsModel} from '@api/models/vehiclesModels';
import {ReactComponent as VisibleSVG} from '@assets/icons/visible.svg';
import {ReactComponent as HiddenSVG} from '@assets/icons/hidden.svg';
import {ReactComponent as CarConfigSVG} from '@assets/icons/car-config.svg';
import {ReactComponent as SettingsSVG} from '@assets/icons/settings.svg';
import {Link} from 'react-router-dom';
import styles from './VehiclesList.module.scss';
import classNames from 'classnames';
import SettingsModal from '../SettingsModal/SettingsModal';

interface VehiclesListProps {
	openModal: (id: number) => void;
}

const VehiclesList = (props: VehiclesListProps) => {
	const {openModal} = props;

	const disptach = useDispatch();
	const [allBrands, setAllBrands] = useState<BrandModel[]>([]);
	const [allBodies, setAllBodies] = useState<BodyModel[]>([]);
	const [modal, setModal] = useState<VehicleDetailsModel | false>(false);
	const [settingsModal, setSettingsModal] = useState<
		VehicleDetailsModel | false
	>(false);

	const vehicles = useSelector(getVehiclesPageVehicles);

	const tableElements = useMemo(
		() =>
			vehicles?.map((item) => {
				return {
					id: item.id,
					brand: item.brand,
					model: item.model,
					bodyType: item.bodyType,
					cabin: item.cabinType,
					chassis: item.chassis,
					status: (
						<button
							className={styles.btn}
							type='button'
							onClick={() => setModal(item)}>
							{item.isHidden ? <HiddenSVG /> : <VisibleSVG />}
						</button>
					),
					edit: (
						<Link className={styles.btn} to={`/vehicles/add?vehicleId=${item.id}`}>
							<CarConfigSVG />
						</Link>
					),
					settings: (
						<button
							className={classNames(styles.btn, styles.btnSvg)}
							type='button'
							onClick={() => setSettingsModal(item)}>
							<SettingsSVG />
						</button>
					),
					delete: (
						<button
							type='button'
							className='button-delete'
							onClick={() => {
								openModal(item.id);
							}}>
							<Trash />
						</button>
					),
				};
			}),
		[vehicles, openModal]
	);

	useEffect(() => {
		(async () => {
			disptach(loadingAction(true));
			await Promise.all([
				getBrandsRequest(),
				getBodiesRequset(),
			]).then(([brands, bodies]) => {
				setAllBrands(brands);
				setAllBodies(bodies);
			});
			disptach(loadingAction(false));
		})();
	}, [disptach]);

	return (
		<>
			<Container as={Tag.Section}>
				<Filters
					filters={[
						{
							name: 'brandIds',
							label: 'Marka',
							options: allBrands.map((brand) => ({
								value: `${brand.id}`,
								name: brand.name,
							})),
						},
						{
							name: 'bodyTypeIds',
							label: 'Zabudowa',
							options: allBodies.map((body) => ({
								value: `${body.id}`,
								name: body.name,
							})),
						},
					]}
				/>
				<Table
					headers={[
						{text: 'ID', sortName: 'id'},
						{text: 'Marka', sortName: 'brand'},
						{text: 'Model', sortName: 'model'},
						{text: 'Zabudowa', sortName: 'body'},
						{text: 'Kabina', sortName: 'cabin'},
						{text: 'Podwozie', sortName: 'chassis'},
						{text: 'Status'},
						{text: 'Konfiguruj'},
						{text: 'Ustawienia'},
						{text: 'Usuń'},
					]}
					elements={tableElements ?? []}
					groups={[
						{width: '5%'},
						{width: '10%'},
						{width: '10%'},
						{width: '10%'},
						{width: '10%'},
						{width: '10%'},
						{width: '2.5%'},
						{width: '2.5%'},
						{width: '2.5%'},
						{width: '2.5%'},
					]}
				/>
				<Pagination
					request={getVehiclesRequest}
					getMaxPages={getVehiclesPageMaxPage}
				/>
			</Container>
			<StatusModal vehicle={modal} closeHandler={() => setModal(false)} />
			<SettingsModal
				bodies={allBodies}
				vehicle={settingsModal}
				closeHandler={() => setSettingsModal(false)}
			/>
		</>
	);
};

export default VehiclesList;
