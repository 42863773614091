import SectionContainer from '@components/SectionContainer/SectionContainer';
import Table from '@components/Table/Table';
import {getPreviewOffer} from '@selectors/previewOfferSelectors';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import styles from './ConfigurationData.module.scss';
import Info from '@components/Info/Info';

const ConfigurationData = () => {
	const offerDetails = useSelector(getPreviewOffer);

	const elements = useMemo(() => {
		if (!!!offerDetails) return [];

		return [
			{
				basePrice: (
					<span className={styles.element}>
						{`${offerDetails.totalPrice} zł ${
							!!offerDetails.totalPriceEuro ? `${offerDetails.totalPriceEuro} €` : ''
						}`}
						<Info
							id={`${offerDetails.id}`}
							content='(cena bazowa + ceny cech + koszt transportu) / rabat  + marża klienta'
						/>
					</span>
				),
				margin: `${offerDetails.clientMargin} zł`,

				costs: (
					<span className={styles.element}>
						{`${offerDetails.totalCosts} zł${
						offerDetails.totalCostsEur !== 0 ? ` ${offerDetails.totalCostsEur} €` : ''
					}`}
						<Info
							id={`${offerDetails.id}`}
							content='koszta bazowe + koszta cech + rezerwa'
						/>
					</span>
				),
				profit: `${offerDetails.profit} zł ${
					!!offerDetails.profitEur ? `${offerDetails.profitEur} €` : ''
				}`,
			},
		];
	}, [offerDetails]);

	return (
		<SectionContainer title='Podsumowanie'>
			<div className={styles.table}>
				<Table
					headers={[
						{text: 'Cena'},
						{text: 'Marża klienta'},
						{text: 'Koszta'},
						{text: 'Zysk'},
					]}
					elements={elements}
					groups={[
						{width: '25%', minWidth: 130},
						{width: '25%', minWidth: 130},
						{width: '25%', minWidth: 100},
						{width: '25%', minWidth: 100},
					]}
				/>
			</div>
		</SectionContainer>
	);
};

export default ConfigurationData;
