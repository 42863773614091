import {Tag} from '@commonTypes/tags';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import Container from '@components/Container/Container';
import React from 'react';
import {SectionContainerProps} from './SectionContainerProps';
import styles from './SectionContainer.module.scss';
import classNames from 'classnames';

const SectionContainer: React.FC<SectionContainerProps> = (props) => {
	const {title, children, classes} = props;

	return (
		<Container as={Tag.Section} classes={classNames(styles.wrapper, classes)}>
			<div className={styles.content}>
				<Title
					tag={TitleTag.h2}
					variant={TitleVariant.standard}
					classes={styles.title}>
					{title}
				</Title>
				{children}
			</div>
		</Container>
	);
};

export default SectionContainer;
